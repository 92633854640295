import { FaRobot } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";
import { Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setActive, toggleActive } from "../../store/slices/ChatbotSlice";
import ChatBox from './components/ChatBox';
import style from './style.module.css';

export default function ChatBot() {
  const dispatch = useDispatch();
  const handleChatToggle = () => { dispatch(toggleActive()); };
  const {active} = useSelector(state=>state.chatState)
  return (
    <div className={style.chat_space}>
      {/*_________________________ F L O A T I N G   B U T T O N___________________________ */}

        <Tooltip title="Chat With Us" placement="left">
          <Button
            onClick={handleChatToggle}
            className={`${style.Floating_Btn} ${active ? style.active_btn : style.disactive_btn}`}
            icon={ !active ? <FaRobot className={style.chat_start_icon}/> : <MdOutlineClose />}
          />
        </Tooltip>

      {/* ________________________________C H A T  B O X_____________________________________ */}
      
        <ChatBox/>

      {/* ________________________________O V E R L A Y______________________________________ */}
      

        {active && ( <div onClick={() => dispatch(setActive(false))} className={style.overlay} > </div> )}

      {/* ___________________________________________________________________________________ */}

    </div>
  );
}
