import PropTypes from 'prop-types';
import React from 'react';
import { withNProgress } from '@tanem/react-nprogress';

import Bar from './Bar';
import Container from './Container';
import Spinner from './Spinner';
import { Spin } from 'antd';

const Progress = ({ isFinished, progress, animationDuration }) => {
  return (
    <Container animationDuration={animationDuration} isFinished={isFinished}>
      <Bar animationDuration={animationDuration} progress={progress} />
      <Spin size='large' style={{ margin: "auto 50%", alignContent: "center", height: '100vh' }} />
    </Container>
  );
};

Progress.propTypes = {
  animationDuration: PropTypes.number,
  progress: PropTypes.number,
  isFinished: PropTypes.bool
};

export default withNProgress(Progress);
