// src/components/NavLogo.js
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleCollapseMenu } from "../../../../store/slices/layoutSlice";  // Assuming this action is already created

const NavLogo = () => {
  const dispatch = useDispatch();

  // Accessing collapseMenu state from Redux
  const { collapseMenu } = useSelector((state) => state.layout);

  // Toggle class for mobile menu icon
  let toggleClass = ["mobile-menu"];
  if (collapseMenu) {
    toggleClass = [...toggleClass, "on"];
  }

  return (
    <div className="navbar-brand header-logo">
      <div className="b-brand">
        <div className="b-bg">
          <i className="feather icon-activity" />
        </div>
        <span className="b-title">SIAA</span>
      </div>

      {/* Mobile collapse button */}
      <div
        className={toggleClass.join(" ")}
        role="button"
        id="mobile-collapse"
        onClick={() => dispatch(toggleCollapseMenu())} // Dispatch action to toggle menu
      >
        <span />
      </div>
    </div>
  );
};

export default NavLogo;
