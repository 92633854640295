import { createSlice } from '@reduxjs/toolkit';

const initialState = {
        filteredBreakfast: {},
        filteredLunch:{},
        filteredDinner:{}
};

export const filteredMealSlice = createSlice({
    name: 'filteredMealState',
    initialState,
    reducers: {
        setFilteredBreakfast : (state, action) => {
            state.filteredBreakfast = action.payload  
        },setFilteredLunch : (state, action) => {
            state.filteredLunch = action.payload;
        },setFilteredDinner : (state, action) => {
            state.filteredDinner = action.payload;
        }
    },
});

export const { setFilteredBreakfast,setFilteredDinner,setFilteredLunch} = filteredMealSlice.actions;
export default filteredMealSlice.reducer;
