// src/components/Navigation.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCollapseMenu } from '../../../store/slices/layoutSlice';
import useWindowSize from '../../../hooks/useWindowSize';
import NavLogo from './NavLogo';
import NavContent from './NavContent';
import navigation from '../../../components/menu-items';
import './style.css'
const Navigation = () => {
  // Get collapseMenu and layoutType from the Redux state
  const { layoutType, collapseMenu } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  // Default navClass array
  let navClass = ['pcoded-navbar'];

  // Add layoutType class (e.g., 'vertical', 'horizontal')
  navClass = [...navClass, layoutType];

  // If on mobile and the menu is collapsed, apply 'mob-open', else apply 'navbar-collapsed'
  if (windowSize.width < 992 && collapseMenu) {
    navClass = [...navClass, 'mob-open'];
  } else if (collapseMenu) {
    navClass = [...navClass, 'navbar-collapsed'];
  }

  // Nav content wrapper class
  let navBarClass = ['navbar-wrapper'];

  let navContent = (
    <div className={navBarClass.join(' ')}>
      <NavLogo />
      <NavContent navigation={navigation.items} />
    </div>
  );

  if (windowSize.width < 992) {
    navContent = (
      <div className="navbar-wrapper">
        <NavLogo />
        <NavContent navigation={navigation.items} />
      </div>
    );
  }

  return (
    <React.Fragment>
      {/* Navigation bar */}
      <nav className={navClass.join(' ')} id="sidBar_style">{navContent}</nav>
    </React.Fragment>
  );
};

export default Navigation;
