import { jwtDecode } from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";


export const PrivateRouteBasedOnAdminRole = () => {
    const userData = jwtDecode(localStorage.getItem('access_token'))
    const checkAuthorizedUser = userData && userData.role === 'admin' ? true : false;
    return checkAuthorizedUser ? <Outlet /> : <Navigate to='/' />;
};

export const PrivateRouteBasedOnUserRole = () => {
    const userData = jwtDecode(localStorage.getItem('access_token'))
    const checkAuthorizedUser = userData && userData.role === 'user' ? true : false;
    return checkAuthorizedUser ? <Outlet /> : <Navigate to='/' />;
};
