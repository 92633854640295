import { useState } from 'react';
import { console_log } from '../utils/console_log';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PostService } from './services/requests-service';
import { showToast } from '../components/Toast/Toast';
import { setAuthData } from '../store/slices/authDataSlice';

const usePostData = () => {
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState(null);
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ====handle Logout====
  const handleLogout = (error) => {
    localStorage.clear();
    dispatch(setAuthData({ authData: {}, auth: false }));
    navigate('/signin');
    setErrors({ error: error?.response?.data?.error });
    showToast({ text: error, status: false });
  };

  const postData = async ({ route, data, params, onUploadProgress = () => { } }) => {
    try {
      setLoading(true);
      const res = await PostService({ route, data, params, onUploadProgress });
      console_log("res ====>", { route, data, res });
      setLoading(false);
      setErrors(null);
      setResData(res.data);
      console_log("res post", res.data);
      return res;

    } catch (error) {
      //    handle error msg ()
      console_log("res err ====>", error?.response?.data?.message);
      showToast({ text: error?.response?.data?.message, status: false });
      console_log({ error });
      setErrors(error);
      setLoading(false);
      const status = error?.response?.status;

      if (status === 401 || status === 403) {
        handleLogout(error?.response?.data?.error);
      } else {
        setErrors({ error: error?.response?.data?.error });
        showToast({ text: error?.response?.data?.error, status: error?.response?.data?.success, });
      }

      return { error: error.response.data.error };
    }
  };

  return (
    {
      loading,
      data: resData,
      setData: setResData,
      errors,
      postData,
      setLoading: setLoading
    }
  );
};


export default usePostData;