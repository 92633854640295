import React, { useState } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { setActive } from '../../../../store/slices/ChatbotSlice';
import { useDispatch } from 'react-redux';
import { CgProfile } from "react-icons/cg";
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import '../style.css';

const NavRight = () => {
  const dispatch = useDispatch();
  const userName = jwtDecode(localStorage.getItem('access_token')).username
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const HideDimScreen = () => {
    setDropdownVisible(false)
  }
  const menu = (
    <Menu>
      <Menu.Item key="goals">
        <Link to={'/goals'} onClick={HideDimScreen}>Goals</Link>
      </Menu.Item>
      <Menu.Item key="reports">
        <Link to={'/reports'} onClick={HideDimScreen}>Reports</Link>
      </Menu.Item>
      <Menu.Item key="profile">
        <Link to={'/profile'} onClick={HideDimScreen}>Profile</Link>
      </Menu.Item>
      <Menu.Item key="chat">
        <span onClick={() => dispatch(setActive(true))}>SIAA Chat</span>
      </Menu.Item>

    </Menu>
  );

  const handleVisibleChange = (flag) => {
    setDropdownVisible(flag);
  };

  return (
    <>
      {dropdownVisible && <div className="overlay" onClick={() => setDropdownVisible(false)} />}
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        visible={dropdownVisible}
        onVisibleChange={handleVisibleChange}
      >
        <Button onClick={(e) => e.preventDefault()} type="text">
          <div className='profileDiv'>
            <span>{userName}</span>
            <CgProfile className='iconStyle' />
          </div>
        </Button>
      </Dropdown>
    </>
  );
};

export default NavRight;
