import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import navigation from "../menu-items";
import styles from './style.module.css'

const Breadcrumb = () => {
  const location = useLocation();

  const [main, setMain] = useState([]);
  const [item, setItem] = useState([]);
  const activeLink = (url) => location.pathname === url ? styles.active : "";


  useEffect(() => {
    navigation.items.map((item, index) => {
      if (item.type && item.type === "group") {
        getCollapse(item, index);
      }
      return false;
    });
  });

  const getCollapse = (item, index) => {
    if (item.children) {
      item.children.filter((collapse) => {
        if (collapse.type && collapse.type === "collapse") {
          getCollapse(collapse, index);
        } else if (collapse.type && collapse.type === "item") {
          if (location.pathname === collapse.url) {
            setMain(item);
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  let mainContent, itemContent;
  let breadcrumbContent = "";
  let title = "";

  if (main && main.type === "collapse") {
    mainContent = (
      <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
        <Link to="#">{main.title}</Link>
        {mainContent}
        {itemContent}
      </ListGroup.Item>
    );
  }

  if (item && item.type === "item") {
    title = item.title;
    itemContent = (
      <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
        <Link to="#">{title}</Link>
        {mainContent}
        {itemContent}
      </ListGroup.Item>
    );

    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <div className="page-header ">
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-header-title"></div>
                <ListGroup as="ul" bsPrefix=" " className="breadcrumb mb-2">
                  <ListGroup.Item
                    as="li"
                    bsPrefix=" "
                    className="breadcrumb-item"
                  >
                    <Link to="/">
                      <i className="feather icon-home" />
                    </Link>
                  </ListGroup.Item>
                  {mainContent}
                  {itemContent}
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      );
    }

    document.title = title + process.env.REACT_APP_BASE_TITLE;
  }

  return <React.Fragment>
    <div className="d-flex justify-content-between align-items-center ">
    {breadcrumbContent}
  <ul className = {styles.optionsInBreadcrumb}>
    <li>
      <Link className={activeLink("/goals")} to="/goals">Goals</Link>
    </li>
    <li>
      <Link className={activeLink("/reports")} to="/reports">Reports</Link>
    </li>
    <li>
      <Link className={activeLink("/profile")} to="/profile">Profile</Link>
    </li>
  </ul>
    </div>

  </React.Fragment>;
};

export default Breadcrumb;
