import { console_log } from "../../utils/console_log";
import axios from "axios";
const environment = process.env.REACT_APP_ENVIRONMENT;
const baseURL = (environment === 'local' ? process.env.REACT_APP_API_LOCAL : environment === 'dev' ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API_PROD) || process.env.REACT_APP_API_LOCAL;
const Axios = axios.create({ baseURL });
Axios.defaults.baseURL = baseURL;
Axios.defaults.headers["x-app-token"] = "Siaa-ai";



Axios.interceptors.request.use(async (req) => {
  let token = localStorage.getItem("access_token");
  if (req.url === "signin") {
    return req;
  }

  if (req.url === "register") {
    console_log(req, "req in if");
    return req;
  }


  let updatedParams = { ...req?.params };
  if (!req?.url.includes("/list")) {
    delete updatedParams?.page;
    delete updatedParams?.limit;
    delete updatedParams?.pageNo;
  }
  delete updatedParams?.tab; // Remove tab key 
  req.params = updatedParams;

  console_log(req, "req axios");
  req.headers.Authorization = `Bearer ${ token }`;
  req.headers["blueKey"] ="hello"
  ///to handle form data
  if (req.data instanceof FormData) {
    req.headers["Content-Type"] = "multipart/form-data";
  }
  return req;
});

Axios.interceptors.response.use(
  (res) => {
    console_log("response ==>", res);

    return res;
  },
  (err) => {
    console_log("error ==>", { err }, err?.response?.data?.error);

    if (err?.response?.status === 401 || err?.response?.status === 403) {
      // showToastErrorMSG(t('your session has expired'));
      //@ts-ignore
    } else {
      // showToastErrorMSG(err?.response?.data?.error);
    }

    return Promise.reject(err);
  }
);

export default Axios;






































































































































































































































































































































































































































































































































































