import { useState, useEffect, useRef } from 'react';
import { GoDependabot } from "react-icons/go";
import { ChatBot } from '../../../assets/images';
import { useSelector } from 'react-redux';
import style from './style.module.css';
import ChatBottom from './ChatBottom';
import usePostData from '../../../api/usePostData';

export default function ChatBox() {
  const { active } = useSelector(state => state.chatState);
  const { postData, loading: loadingSendMessage } = usePostData();
  const [messages, setMessages] = useState([]);
  const [animationKey, setAnimationKey] = useState(0);
  const chatBodyRef = useRef(null);

  //___ H A N D L E  S E N D   M E S S A G E ___//
  const onSendMsg = async (msg) => {
    setMessages(prevMessages => [...prevMessages, { sender: 'user', message: msg, animate: true }]);
    setMessages(prevMessages => [...prevMessages, { sender: 'bot', message: 'Typing...', animate: false }]);

    const res = await postData({ route: "openai/chatbot", data: { message: msg } });
    if (!res?.error) {
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = { sender: 'bot', message: res?.data?.reply || "No valid response", animate: true };
        return updatedMessages;
      });
    }
  };

  useEffect(() => { setAnimationKey(prevKey => prevKey + 1); }, [active]);
  useEffect(() => { if (chatBodyRef.current) { chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight; } }, [messages]);

  return (
    <div className={`${style.Chat_Container} ${active ? style.Chat_Container_active : style.Chat_Container_unactive}`}>
      <div className={style.Chat_Box}>
        <div className={style.Chat_Box_header}>
          <GoDependabot className={style.header_bot_icon}/> SIAA Chatbot
        </div>
        
        <div className={style.Chat_Body} style={{ maxHeight: "435px", minHeight: "435px" }} ref={chatBodyRef}>
          {messages?.length === 0 ? 
          <>
            <img key={`img-${animationKey}`} src={ChatBot} className={style.chatbot_img} />
            <div key={`text-${animationKey}`} className={style.welcome_text}>
              <div className={style.welcome_text_line}>Welcome to SIAA Bot!</div>
              <div className={style.welcome_text_line}>How can I help you?</div>
            </div>
          </>
          :
          <div className={style.conversation}>
            {messages.map((msg, index) => (
              <div
                key={index} 
                style={{ padding: msg.message === 'Typing...' ? "0px" : "8px" }}
                className={`${msg.sender === 'user' ? style.user_message_right : style.bot_message_left} ${msg.animate ? style.message : ''}`}>
                <span className={msg.message === 'Typing...' ? style.loading : ''}>
                  {msg.message}
                </span>
              </div>
            ))}
          </div>
           }
        </div>
        <ChatBottom loadingSendText={loadingSendMessage} onSendMsg={onSendMsg} />
      </div>
    </div>
  );
}
