import React from 'react';
import { Card, Typography, Divider, List, Space, Skeleton } from 'antd';

const { Title, Text } = Typography;

const CookBookSkeleton = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Card style={{ width: 400 }}>
                <div style={{ marginBottom: 50, display: "flex", flexDirection: "column" }}>
                    <Skeleton.Input style={{ width: "100%", marginBottom: 20, height: 20, display: 'block' }} active />
                    <Skeleton.Input style={{ width: "100%", height: 20, margin: '0 auto', display: 'block' }} active />
                </div>
                {[...Array(3)].map((_, index) => (
                    <div key={index} style={{ marginBottom: 20, display: "flex", flexDirection: "column" }}>
                        <Skeleton.Input style={{ width: "100%", height: 20, margin: '0 auto', display: 'block' }} active />
                        <Skeleton.Input style={{ width: "100%", height: 150, margin: '10px auto', display: 'block' }} active />
                    </div>
                ))}
            </Card>
        </div>
    );
};

export default CookBookSkeleton;
