import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Skeleton } from "antd";
import AdminLayout from "../components/dashboadLayout";
import NewPassword from "../pages/auth/signup/NewPassword";
import PrivateRoutes from "./PrivateRoutes";
import SignIn from '../pages/auth/signin/SignIn'
import { PrivateRouteBasedOnAdminRole } from "./PrivateRouteBasedOnRole";
import { PrivateRouteBasedOnUserRole } from './PrivateRouteBasedOnRole'
import Loader from "../components/Loader/Loader";
import CookBookSkeleton from "../components/skeleton/CookBookSkeleton";

const Home = lazy(() => import("../pages/landingPage/home"));
const SignUp = lazy(() => import("../pages/auth/signup"));
const ForgotPassword = lazy(() => import("../pages/auth/signup/forgotPassword"));
const UserDashboard = lazy(() => import("../pages/dashboard"));
const EatingOut = lazy(() => import("../pages/dashboard/eatingout/Eatingout"));
const IngredientAndMenuPage = lazy(() => import("../pages/dashboard/eatingout/IngredientAndMenuPage"));
const EventCalendar = lazy(() => import("../pages/dashboard/calendar/eventCalendar"));
const Groceries = lazy(() => import("../pages/dashboard/groceries/groceries"));
const SignOut = lazy(() => import("../pages/dashboard/signout"));
const CookBook = lazy(() => import("../pages/dashboard/cookBook/CookBook"));
const RecipeDetailedView = lazy(() => import("../pages/dashboard/cookBook/RecipeDetailedView"));
const Goals = lazy(() => import("../pages/dashboard/charts"));
const MealInfo = lazy(() => import("../pages/dashboard/meals/mealInfo"));
const Profile = lazy(() => import("../pages/dashboard/profile"));
const Reports = lazy(() => import("../pages/dashboard/reports"));
const AdminDashboard = lazy(() => import("../pages/admin/AdminDashboard"));

const FullPageSkeleton = () => (
  <div style={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
    <Skeleton
      active
      paragraph={{ rows: 1, width: "100%" }}
      title={false}
      style={{ width: "100%", height: "100vh" }}
      className="main-skeleton"
    />
  </div>
);
const RoutesConfig = () => (
  <Routes>
    {/* Pre-signup routes */}
    <Route
      path="/"
      element={
        <Suspense fallback={<Loader />}>
          <Home />
        </Suspense>
      }
    />
    <Route
      path="/signin"
      element={
        <Suspense fallback={<Loader />}>
          <SignIn />
        </Suspense>
      }
    />
    <Route
      path="/signup"
      element={
        <Suspense fallback={<Loader />}>
          <SignUp />
        </Suspense>
      }
    />
    <Route
      path="/forgetPassword"
      element={
        <Suspense fallback={<Loader />}>
          <ForgotPassword />
        </Suspense>
      }
    />
    <Route path="/reset-password" element={<NewPassword />} />

    {/* Post-signup routes (protected by AdminLayout) */}
    <Route element={<PrivateRoutes />}>
      <Route path="*" element={<AdminLayout />}>
        <Route element={<PrivateRouteBasedOnUserRole />}>
          <Route
            path="user-dashboard"
            element={
              <Suspense fallback={<FullPageSkeleton />}>
                <UserDashboard />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="eating-out"
          element={
            <Suspense fallback={<FullPageSkeleton />}>
              <EatingOut />
            </Suspense>
          }
        />
        <Route
          path="ingredient-and-menus"
          element={
            <Suspense fallback={<FullPageSkeleton />}>
              <IngredientAndMenuPage />
            </Suspense>
          }
        />
        <Route
          path="mealType/:mealType"
          element={
            <Suspense fallback={<FullPageSkeleton />}>
              <MealInfo />
            </Suspense>
          }
        />
        <Route
          path="calendar"
          element={
            <Suspense fallback={<FullPageSkeleton />}>
              <EventCalendar />
            </Suspense>
          }
        />
        <Route
          path="groceries"
          element={
            <Suspense fallback={<FullPageSkeleton />}>
              <Groceries />
            </Suspense>
          }
        />
        <Route
          path="signout"
          element={
            <Suspense fallback={<FullPageSkeleton />}>
              <SignOut />
            </Suspense>
          }
        />
        <Route
          path="reports"
          element={
            <Suspense fallback={<FullPageSkeleton />}>
              <Reports />
            </Suspense>
          }
        />
        <Route
          path="cookbook"
          element={
            <Suspense fallback={<FullPageSkeleton />}>
              <CookBook />
            </Suspense>
          }
        />
        <Route
          path="recipeDetails"
          element={
            <Suspense fallback={<CookBookSkeleton />}>
              <RecipeDetailedView />
            </Suspense>
          }
        />
        <Route
          path="goals"
          element={
            <Suspense fallback={<FullPageSkeleton />}>
              <Goals />
            </Suspense>
          }
        />
        <Route
          path="profile"
          element={
            <Suspense fallback={<FullPageSkeleton />}>
              <Profile />
            </Suspense>
          }
        />
      </Route>
    </Route>
    <Route element={<PrivateRouteBasedOnAdminRole />}>
      <Route
        path="/admin-dashboard"
        element={
          <Suspense fallback={<FullPageSkeleton />}>
            <AdminDashboard />
          </Suspense>
        }
      />
    </Route>

    {/* Redirect to home if no route matches */}
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default RoutesConfig;
