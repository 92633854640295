// src/components/AdminLayout.js
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCollapseMenu } from '../../store/slices/layoutSlice';
import Navigation from './Navigation';
import NavBar from './NavBar';
import Breadcrumb from '../../components/Breadcrumb';
import useWindowSize from '../../hooks/useWindowSize';
import useOutsideClick from '../../hooks/useOutsideClick';
import ChatBot from '../../components/Chat';
import './style.css'
const AdminLayout = ({ children }) => {
  const windowSize = useWindowSize();
  const ref = useRef();
  const dispatch = useDispatch();

  // Access the state from Redux
  const { collapseMenu, headerFixedLayout } = useSelector((state) => state.layout);

  // Handle outside click to collapse the menu
  useOutsideClick(ref, () => {
    if (collapseMenu) {
      dispatch(toggleCollapseMenu());
    }
  });

  const mobileOutClickHandler = () => {
    if (windowSize.width < 992 && collapseMenu) {
      dispatch(toggleCollapseMenu());
    }
  };

  // Overlay visibility based on collapseMenu state
  const overlayClass = (collapseMenu && windowSize.width < 992) ? 'overlayScreen overlayScreenActive' : 'overlayScreenOff';

  let mainClass = ['pcoded-wrapper'];

  let common = (
    <React.Fragment>
      <Navigation />
      <NavBar />
    </React.Fragment>
  );

  let mainContainer = (
    <React.Fragment>
      <div className="pcoded-main-container">
        <div className={mainClass.join(' ')}>
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb />
              <Outlet />
              <ChatBot />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  // Handle mobile view when the collapse menu is active
  if (windowSize.width < 992) {
    let outSideClass = ['nav-outside'];
    if (collapseMenu) {
      outSideClass = [...outSideClass, 'mob-backdrop'];
    }
    if (headerFixedLayout) {
      outSideClass = [...outSideClass, 'mob-fixed'];
    }

    common = (
      <div className={outSideClass.join(' ')} ref={ref}>
        {common}
      </div>
    );

    mainContainer = (
      <div
        role="button"
        tabIndex="0"
        className="pcoded-outside"
        onClick={mobileOutClickHandler}
        onKeyDown={mobileOutClickHandler}
      >
        {mainContainer}
      </div>
    );
  }

  return (
    <React.Fragment>
      {common}
      {mainContainer}

      {/* Overlay for collapsing the menu on click */}
      <div
        className={overlayClass}
        onClick={() => dispatch(toggleCollapseMenu())} // Close the menu on overlay click
      />
    </React.Fragment>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node,
};

export default AdminLayout;
