import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Form } from "react-bootstrap";
import "./style/Signin.scss";
import "react-toastify/dist/ReactToastify.css";
import usePostData from "../../../api/usePostData";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { setAuthData } from "../../../store/slices/authDataSlice";

const SignIn = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { postData } = usePostData();
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    const res = await postData({ route: `auth/login`, data: credentials });
    if (!res?.error) {
      const { access_token, refresh_token } = res.data.tokens;
      localStorage.clear();
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      const userInfo = jwtDecode(access_token);
      dispatch(setAuthData({ authData: userInfo, auth: true }));
      userInfo.role === "admin" ? handleNavigate("/admin-dashboard") : handleNavigate("/user-dashboard");
      
    } else {
      setErrorMessage("Invalid email or password.");
    }
  };

  const handleNavigate = (path, message = "") => {
    navigate(path);
    if (message) toast.success(message);
  };

  return (
    <Form className="container-signin" onSubmit={handleSubmitLogin}>
      <Form.Group className="form-group-signin">
        <Form.Text className="title">
          <NavLink className="navbar-brand align-items-center" to="/">
            SIAA<span className="navbar-brand-ai text-dark"> AI</span>
          </NavLink>
        </Form.Text>
        <Col xs="10" sm="10" md="10" xl="10">
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              name="email"
              value={credentials.email}
              placeholder="Email Address"
              onChange={handleChange}
              className="mb-3"
              required
            />
            <Form.Control
              type="password"
              name="password"
              value={credentials.password}
              placeholder="Password"
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="d-flex flex-column justify-content-center align-items-center">
            <Button
              className="sign-up-btn-primary-login"
              type="submit"
              variant="success"
            >
              Login
            </Button>
            <Button
              variant="outline-secondary"
              className="sign-up-btn-primary-forgot text-center my-2"
              onClick={() => handleNavigate("/forgetPassword")}
            >
              Forgot Password
            </Button>
            <Button
              variant="secondary"
              className="sign-up-btn-primary-forgot my-2"
              onClick={() => handleNavigate("/", "Welcome Back To Dashboard")}
            >
              Cancel
            </Button>
          </Form.Group>
        </Col>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </Form.Group>
    </Form>
  );
};

export default SignIn;
