// store.js
import { 
  useDispatch as useReduxDispatch, 
  useSelector as useReduxSelector, 
} from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import authDataReducer from "./slices/authDataSlice";
import mealReducer from "./slices/mealSlice";
import filteredMealReducer from "./slices/filteredMealSlice";
import chatReducer from "./slices/ChatbotSlice";
import layoutReducer from './slices/layoutSlice';

const store = configureStore({
  reducer: {
    authState: authDataReducer,
    mealState: mealReducer,
    filteredMealState: filteredMealReducer,
    chatState: chatReducer,
    layout: layoutReducer,
  },
});

// Optional
export const useSelector = useReduxSelector; 
export const useDispatch = () => useReduxDispatch();

export default store;
