import { FaArrowLeftLong, FaBreadSlice, FaUtensils, FaDrumstickBite, FaBookmark, FaCalendar, FaCartShopping } from 'react-icons/fa6'
import { FaHome } from 'react-icons/fa'
import { LuLineChart } from 'react-icons/lu'
import { IoChevronBackCircleOutline } from 'react-icons/io5'
import { FaUserAlt } from "react-icons/fa";

const menuItems = {
  items: [
    {
      id: "navigation",
      title: "",
      type: "group",
      icon: "icon-navigation",
      children: [
        {
          id: "landing-page",
          title: "Landing Page",
          type: "item",
          icon: <FaArrowLeftLong />,
          url: "/",
        },
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          icon: <FaHome />,
          url: "/user-dashboard",
        },
        {
          id: "goals",
          title: "Goals",
          type: "item",
          icon: <LuLineChart />,
          url: "/goals",
        },
        {
          id: "breakfast",
          title: "Breakfast",
          type: "item",
          icon: <FaBreadSlice />,
          url: "/mealType/breakfast",
        },
        {
          id: "lunch",
          title: "Lunch",
          type: "item",
          icon: <FaUtensils />,
          url: "/mealType/lunch",
        },
        {
          id: "dinner",
          title: "Dinner",
          type: "item",
          icon: <FaDrumstickBite />,
          url: "/mealType/dinner",
        },
        {
          id: "cookbook",
          title: "Cook Book",
          type: "item",
          icon: <FaBookmark />,
          url: "/cookbook",
        },

        {
          id: "calender",
          title: "Calendar",
          type: "item",
          icon: <FaCalendar />,
          url: "/calendar",
        },
        {
          id: "groceries",
          title: "Groceries",
          type: "item",
          icon: <FaCartShopping />,
          url: "/groceries",
        },
        {
          id: "userProfile",
          title: "Profile",
          type: "item",
          icon: <FaUserAlt />,
          url: "/profile",
        },
        {
          id: "signout",
          title: "Sign Out",
          type: "item",
          icon: <IoChevronBackCircleOutline />,
          url: "/signout",
        },
      ],
    },
  ],
};

export default menuItems;