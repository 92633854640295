// slices/authDataSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from "jwt-decode";

const initialState = {
  authData: localStorage.getItem('access_token')
    ? jwtDecode(localStorage.getItem('access_token'))
    : {},
  auth: !!localStorage.getItem('access_token'), // Use double negation for a boolean
};

export const authDataSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.authData = action?.payload?.auth ? action.payload.authData : state.authData
      state.auth = action?.payload?.auth ? action.payload.auth : state.auth
    },
    refreshToken: (state, action) => {
      const token = action.payload;
      localStorage.setItem("access_token", token);
      const decodedData = jwtDecode(token);
      localStorage.setItem("userData", JSON.stringify(decodedData));
      state.authData = decodedData;
      state.auth = true; // Update auth state if token is valid
    },
    logout: (state) => {
      localStorage.clear();
      state.authData = {};
      state.auth = false;
    }
  },
});

export const { setAuthData, refreshToken, logout } = authDataSlice.actions;
export default authDataSlice.reducer;
