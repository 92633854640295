// src/store/slices/layoutSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  collapseMenu: false,           // Controls the overall collapse state of the menu
  headerFixedLayout: false,      // Controls whether the header is fixed
  layoutType: 'vertical',        // Default layout type
  layout: 'horizontal',          // Default layout
  isOpen: [],                    // Stores IDs of open collapsed menus
  isTrigger: [],                 // Stores IDs of triggered collapse menus
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    // Toggle the overall collapse of the sidebar
    toggleCollapseMenu(state) {
      state.collapseMenu = !state.collapseMenu;
    },

    // Toggle individual collapse items (menu IDs)
    toggleCollapseItem(state, action) {
      const { id } = action.payload;
      const index = state.isOpen.findIndex((itemId) => itemId === id);
      if (index > -1) {
        // Close the menu if it's already open
        state.isOpen = state.isOpen.filter((itemId) => itemId !== id);
      } else {
        // Open the menu if it's not already open
        state.isOpen.push(id);
      }
    },

    // Set the layout type (e.g., 'vertical' or 'horizontal')
    setLayout(state, action) {
      state.layoutType = action.payload;
    },

    // Set the header fixed layout
    setHeaderFixedLayout(state, action) {
      state.headerFixedLayout = action.payload;
    },

    // Set the layout style (could be horizontal or vertical)
    setLayoutStyle(state, action) {
      state.layout = action.payload;
    },
  },
});

// Export the actions so they can be dispatched
export const {
  toggleCollapseMenu,
  toggleCollapseItem,
  setLayout,
  setHeaderFixedLayout,
  setLayoutStyle,
} = layoutSlice.actions;

export default layoutSlice.reducer;
