// slices/chatSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    active: false,
};

export const chatSlice = createSlice({
    name: 'chatState',
    initialState,
    reducers: {
        // Explicitly set active state to true or false based on payload
        setActive: (state, action) => {
            state.active = action.payload;
        },
        // Separate reducer to toggle the active state
        toggleActive: (state) => {
            state.active = !state.active;
        }
    },
});

// Export actions
export const { setActive, toggleActive } = chatSlice.actions;

// Export reducer
export default chatSlice.reducer;
