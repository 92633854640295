import Axios from "./axios";

const GetService = async ({ route, params }) => {
  return await Axios.get(route, { params });
};


const PostService = async ({ route, data, params, onUploadProgress }) => {
  try {
    const config = { ...params, ...(onUploadProgress && { onUploadProgress }) };
    const response = await Axios.post(route, data, config);
    return response;
  } catch (error) {
    throw error;
  }
};

const DeleteService = async ({ route, data, params }) => {
  const response = await Axios.delete(route, { params, data });
  return response;
};

const PutService = async ({ route, params, data }) => {
  const response = await Axios.put(route, data, { params });
  return response;
};

export { PostService, DeleteService, GetService, PutService };