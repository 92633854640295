// src/components/NavBar.js
import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleCollapseMenu } from "../../../store/slices/layoutSlice";  // Assuming you created this slice for the collapseMenu
import NavRight from "./NavRight";
import './style.css';

const NavBar = () => {
  const dispatch = useDispatch();

  // Accessing layout state from Redux
  const { collapseMenu, headerFixedLayout, layout } = useSelector((state) => state.layout);

  let headerClass = ["navbar", "pcoded-header", "navbar-expand-lg"];
  if (headerFixedLayout && layout === "vertical") {
    headerClass = [...headerClass, "headerpos-fixed"];
  }

  let toggleClass = ["mobile-menu"];
  if (collapseMenu) {
    toggleClass = [...toggleClass, "on"];
  }

  const navToggleHandler = () => {
    dispatch(toggleCollapseMenu());
  };

  return (
    <header className={headerClass.join(" ")}>
      <div className="m-header">
        <Link to="#" className="b-brand">
          <div className="b-bg-mobile">
            <i className="feather icon-activity" />
          </div>
          <span className="b-title-mobile">SIAA</span>
        </Link>
        <div className="nav-content-box">
          <Button
            type="link"
            className={toggleClass.join(" ")}
            id="mobile-collapse"
            onClick={navToggleHandler}
          >
            <span />
          </Button>
          <NavRight />
        </div>
      </div>
    </header>
  );
};

export default NavBar;
