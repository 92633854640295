import React, { useState } from 'react';
import { Button, Input, Spin } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import style from './style.module.css';
const { TextArea } = Input;

const ChatBottom = ({loadingSendText, onSendMsg}) => {
  const [message, setMessage] = useState('');
  const handleMessageChange = (event) => { setMessage(event.target.value); };
  const handleSendMessage = () => {
    setMessage('');
    if (message.trim()) { onSendMsg(message.trim()); }
  };

  return (
    <div className={style.Chat_Bottom}>
        <TextArea onChange={handleMessageChange} value={message} placeholder="Type something..." autoSize={{ minRows: 1, maxRows: 6 }} className={style.message_input} />
        <Button onClick={handleSendMessage} className={style.Send_Button} > {loadingSendText ? ( <Spin size="small" /> ) : <SendOutlined /> }</Button>
    </div>
  );
};

export default ChatBottom;
