import RoutesConfig from "./routes";


const App = () => {
  return (
    <>
      <RoutesConfig />
    </>
  );
};

export default App;
