import React, { useContext, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../signin/style/Signin.scss";
import AppInput from '../../../components/AppInput';
import { Button } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import { showToast } from './../../../components/Toast/Toast';
import usePostData from "../../../api/usePostData";
const forgotPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter'),
  confirmPassword: Yup.string()
    .required('Please confirm your password') // Ensures confirmPassword is mandatory
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
});
const initialValue = {
  newPassword: '',
  confirmPassword: ''
};
const NewPassword = () => {
  const { postData, loading } = usePostData();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token'); // Extract the 'token' parameter
  const ResetPassword = async (values, { setSubmitting }) => {
    const res = await postData({ route: `auth/reset-password?token=${token}`, data: { newPassword: values.newPassword } });
    if (!res?.error) {
      showToast({ text: "Password Updated successfully", status: res?.data?.success, });
      navigate("/signin");
    }
    setSubmitting(false);
  };
  return <div className="container-signin ">
    <div className="form-group-signin">
      <h2>New Password</h2>
      <Formik
        validationSchema={forgotPasswordSchema}
        initialValues={initialValue}
        onSubmit={ResetPassword}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              <Form className='w-75'>
                <AppInput
                  name='newPassword'
                  placeholder="password"
                  type="password"
                  hideLabel={false}
                />
                <AppInput
                  name='confirmPassword'
                  placeholder="Confirm Password"
                  type='password'
                  hideLabel={false}
                />
                <Button htmlType="submit" className='w-100' type="primary" size="large" style={{
                  marginTop: "10px"
                }}>
                  Save new password
                </Button>
              </Form>
            </>
          );
        }}
      </Formik >
    </div>
  </div>;
};
export default NewPassword;