// slices/mealSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
        breakfast: [],
        lunch:[],
        dinner:[]
};

export const mealSlice = createSlice({
    name: 'mealState',
    initialState,
    reducers: {
        setBreakfast: (state, action) => {
            state.breakfast = action.payload;
        },setLunch : (state, action) => {
            state.lunch = action.payload;
        },setDinner : (state, action) => {
            state.dinner = action.payload;
        }
    },
});
export const { setBreakfast,setLunch,setDinner } = mealSlice.actions;
export default mealSlice.reducer;
