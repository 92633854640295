import { Input, Checkbox } from 'antd';
import { Field, ErrorMessage } from 'formik';
import TextArea from 'antd/es/input/TextArea';

const AppInput = (props) => {
   const {
      name,
      label,
      type,
      placeholder,
      disabled,
      hideLabel,
      hideError,
      changeHandler,
   } = props;
   const checkType =
      type === 'text' ||
      type === 'number' ||
      type === 'email' ||
      type === 'password';
   return (
      <>
         <div
            style={{
               display: checkType || type === 'textArea' ? 'block' : 'flex',
               width: type !== 'checkbox' ? '100%' : 'auto',
               marginTop: "10px"
            }}
         >
            {!hideLabel && <label>{label}</label>}
            <Field
               {...props}
               as={checkType ? Input : type === 'textArea' ? TextArea : Checkbox}
               name={name}
               type={type || 'text'}
               placeholder={placeholder}
               size='large'
               disabled={disabled}
               autoComplete={type === 'password' ? 'new-password' : 'off'}
               spellCheck={false}
            />
            <div style={{ height: 20 }}>
               {!hideError && (
                  <ErrorMessage
                     style={{
                        color: '#ff4d4f',
                        marginTop: '5px',
                        fontSize: 14,
                        fontWeight: 400,
                     }}
                     name={name}
                     component='div'
                  />
               )}
            </div>
         </div>
      </>
   );
};

export default AppInput;
