
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, Bounce } from "react-toastify";
import App from "./App"; // Ensure App.js has `export default App;`
import { Provider } from "react-redux"; // Keeping the original naming
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css"; // Ensure toastify is properly imported
import store from "./store";
import { ConfigProvider as AntdConfigProvider } from "antd";
import "./index.scss"; // Ensure the styles are properly imported


// Create root and render the app
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <AntdConfigProvider theme={{ token: { colorPrimary: "#198754", colorPrimaryHover: '#136740', } }}>
      <Provider store={store}>
          <Router>
            <ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar={true}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              transition={Bounce}
            />
            <App /> {/* Ensure `App` is correctly imported as default */}
          </Router>
      </Provider>
    </AntdConfigProvider>
  </>
);

// Start measuring performance
reportWebVitals();
